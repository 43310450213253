"use client";

import './style.scss';

import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import useBioFont from '@/hooks/useBioFont';
import { REGEX_UUID, parseJSON } from '../../../utils';
import { conversionPageView } from '../../../utils/conversion-settings';
import BrokenLink from '../../broken-link';
import LoadingAnimation from '../../loading-animation';
import SimpleLandingPage from '../../simple-landing-page';
import UserDispatcher from '@/components/dispatcher/user';

const SimpleLandingPagePage = ({ username, id, currentUser }) => {
  const { t } = useTranslation();
  const [landingPage, setLandingPage] = useState({});
  const [conversionSetting, setConversionSetting] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const identifier = { id: null, link: null };

  if (REGEX_UUID.test(id)) {
    identifier.id = id;
  } else {
    identifier.link = id;
  }

  const fontFamilyName = parseJSON(parseJSON(landingPage.design, {}).fontFamily, {})
  useBioFont(fontFamilyName)

  useEffect(() => {
    if (currentUser) {
      Axios.get(
        `${process.env.NEXT_PUBLIC_MAYAR_USERKV_S3_ENDPOINT}/landing-page/${
          identifier.id ? 'data' : 'data-link'
        }/${process.env.NEXT_PUBLIC_MAYAR_FOLDER_ENV}/BASIC/${
          identifier.id || identifier.link || currentUser?.homePagePath?.split('/')[1]
        }.json`,
      )
        .then((res) => {
          setLandingPage(res.data);

          if (res.data?.conversionSettings?.length > 0) {
            setConversionSetting(res.data.conversionSettings[0]);
          }
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    } else {
      setLandingPage({ errorCode: 404 });
    }
  }, [currentUser]);

  useEffect(() => {
    conversionPageView(conversionSetting);
  }, [conversionSetting]);

  if (error || !currentUser) return <BrokenLink isServerError />;

  if (loading)
    return (
      <div className="responsivearticle">
        <LoadingAnimation responsivearticle="true" />;
      </div>
    );

  const { metaTitle, design, user, name, type, status } = landingPage;

  if (
    status === 'inactive' ||
    status === 'archived' ||
    type !== 'BASIC' ||
    user?.id !== currentUser?.id ||
    landingPage.errorCode === 404
  ) {
    return <BrokenLink message={t('alert.link404')} />;
  }

  return (
    <Fragment>
      {/* <Helmet>
        <title>
          {`${sanitizeDOM(metaTitle) || sanitizeDOM(name)} - ${sanitizeDOM(user?.account?.name)}`}
        </title>
        {user?.account?.logo && <link rel="icon" type="image/png" href={user.account.logo.url} />}
      </Helmet> */}
      <UserDispatcher user={currentUser} />
      <SimpleLandingPage
        data={parseJSON(design, {})}
        userData={currentUser}
        landingPageId={landingPage?.id}
        username={username}
      />
    </Fragment>
  );
};

export default SimpleLandingPagePage;
