/**
 * External Dependencies
 */
"use client";

import React from 'react';
import Content from './content';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/components/ui/dialog"

const PaymentLinkDetailComponent = ({ username, id, children }) => {
  return (
    <>
      <Dialog>
        <DialogTrigger asChild>
          {children}
        </DialogTrigger>
        <DialogContent className="sm:max-w-[625px] max-h-[90vh] overflow-y-auto bg-white">
          <div className="rui-main">
            <div className="rui-sign items-center justify-center">
              <Content username={username} id={id}></Content>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PaymentLinkDetailComponent;
